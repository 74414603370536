import React from "react"
import { Link } from "gatsby"

import Img from "gatsby-image"
import Layout from "../../components/layout"
import Image from "../../components/image"
import SEO from "../../components/seo"
import { Container, Row, Col } from "react-grid-system"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlusCircle,faCheck, faCircleNotch } from "@fortawesome/free-solid-svg-icons"

export default class Page extends React.Component {
    constructor(props) {
        super(props)
        this.state={plans:[]}
    }
    componentDidMount() {
        fetch("https://api.ziegenhagel.com/wp_care_plans.json")
        .then(d=>d.json())
        .then(plans=>this.setState({plans}))

    }
    render() {
        let all_plans=["Silber","Gold","Diamant","Titan"]
        return (
          <Layout container={true} isDark={true}>
            <SEO title="Imagefilm Produktion für Instagram, Facebook und Youtube Spots"></SEO>
            <div className="loosepage text-center">
              <h1 style={{fontSize:"4em"}}>Care<FontAwesomeIcon style={{color:"#9b2",marginLeft:7}} icon={faPlusCircle} className="pulse"></FontAwesomeIcon></h1>
              <p style={{margin:"auto",display:"none",maxWidth:"35em"}}>Professioneller Rundum-Service für Ihre Webseite</p>
              <p style={{margin:"auto",maxWidth:"35em"}}>
                WordPress und das Internet entwickeln sich immer weiter.<br/>
                Leider gibt es neben neuen Funktionen auch zunehmend Schadsoftware.<br/>
                Darum müssen Webseiten aktuell und sicher gehalten werden.</p>
              <p className="text-gray mt-2 text-small" style={{maxWidth:"36em",margin:"auto"}}>
                Care+ ist exklusiv Kund:innen unserer selbst gehosteten Verträge. <br/>
                Vertragslaufzeit 1 Jahr. Rechnungsstellung bei Vertragsabschluss.<br/>
                Alle Preise sind pro Monat aufgelistet.<br/>
              </p>

            </div>    
            {this.state.plans.length < 4  ? <div style={{margin:"199px 0"}}><FontAwesomeIcon icon={faCircleNotch} spin></FontAwesomeIcon> Ihre Angebot wird erstellt ...</div> :
                <>
                <div className="visible-sm">
                    {
                        all_plans.map(plan=>
                            <div className="card has-careicon">
                                <div className={"card-heading careicon careicon-"+plan} key={plan}>
                                    {plan}
                                </div>
                                <div className="card-body">
                                    {this.state.plans !== null && this.state.plans.map(row=>{    
                                        return <div className={"card-section " + (row[plan] === "" ? " disabled " : " ") + ( row.Titel.substr(0,5) === "Preis" && " careicon careicon-"+plan ) } key={row.Titel}>    
                                            {row.Titel.substr(0,5) !== "Preis" && <div>{row.Titel}</div>}
                                            {
                                                row.Titel.substr(0,5) === "Preis" ?
                                                    <div key={plan+row.Titel} className={"careicon careicon-"+plan}>
                                                        <span style={{fontSize:40}}>{row[plan].split("/")[0]}</span>
                                                        <div>{row[plan].split("/")[1]}</div>
                                                        <span>{row[plan].split("/")[2]}</span>
                                                    </div>
                                                :
                                                row[plan] !== "Ja" && row[plan] !== "" && <div><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> {row[plan]}</div>
                                            }
                                        </div>    
                                    })}   
                                    {
                                        
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="hidden-sm table-wrapper">
                    <table className="table-care table-border table-striped table">    
                        <tr>
                            <th className="careicon" width="15%"></th>
                            {all_plans.map(plan=><th width="15%" className={"careicon careicon-"+plan} key={plan}>{plan}</th>)}
                        </tr>
                        {this.state.plans !== null && this.state.plans.map(row=>{    
                            return <tr key={row.Titel}>    
                                <th className={row.Titel.substr(0,5)==="Preis" ? "big-flex" : ""}>{row.Titel}</th>    
                                {all_plans.map(plan=>
                                    row.Titel.substr(0,5) === "Preis" ?
                                        <th key={plan+row.Titel} className={"careicon careicon-"+plan}>
                                            <div style={{fontSize:40,marginBottom:20}}>
                                                {plan!=="Silber" && <div style={{fontSize:"1rem"}} >monatlich</div>}
                                                {row[plan].split("/")[0].replace("mntl.","")}
                                            </div>
                                            <div>{row[plan].split("/")[1]}</div>
                                            <span>{row[plan].split("/")[2]}</span>
                                        </th>
                                    :
                                    <td key={plan+row.Titel}>{row[plan]}</td>
                                )}
                            </tr>    
                        })}    

                    </table>   
                </div>
                </>
            }

            </Layout>
        )
    }
}


